import React from 'react'

import {NextSeo} from 'next-seo'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import WebPage, {WebPageProps} from 'src/components/layout/WebPage'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import urljoin from 'url-join'

import {useAuth} from '@festi/common/contexts'
import {fluidRange} from '@festi/common/utils/styles'

import MyAccountNav, {NavItem} from './MyAccountNav'

const Authenticate = dynamic(
  () => import('src/components/myaccount/Authenticate'),
)

export interface PageProps {
  children: React.ReactNode
}

interface WrapperProps {
  backgroundColor?: string
}

const PageWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${(p) =>
    p.backgroundColor ? palette(p.backgroundColor) : 'transparent'};
`

const PageInner = styled.div`
  display: flex;
  align-items: stretch;
`
const PageContent = styled.div`
  flex-grow: 1;
  width: 100%;
  ${fluidRange('padding-top', '32px', '56px', 'sm', 'lg')};
  ${fluidRange('padding-bottom', '32px', '56px', 'sm', 'lg')};

  ${media.md`
    ${fluidRange('padding-top', '56px', '112px', 'lg', 'xxl')};
    ${fluidRange('padding-bottom', '56px', '112px', 'lg', 'xxl')};
  `}
`

const navItems: NavItem[] = [
  {
    icon: FileList3LineIcon,
    label: 'Nótur',
    pagePath: '/minar',
  },
  {
    icon: UserLineIcon,
    label: 'Mínar upplýsingar',
    pagePath: '/minar/upplysingar',
  },
]

const hiddenNavItem = {
  icon: BuildingLineIcon,
  label: 'Fyrirtæki',
  pagePath: '/minar/fyrirtaeki',
}

export default function MyAccountPage(props: WebPageProps): JSX.Element {
  const {user, userLoading, missingAttributes} = useAuth()

  const newNavItems = [...navItems, hiddenNavItem]

  const seoTitle = props.headTitle || 'ELKO - Mínar síður'
  const seoDescription =
    'Búðu þér til aðgang á elko.is og sjáðu yfirlit yfir þín viðskipti hjá ELKO'

  return (
    <WebPage {...props} myaccount>
      <PageWrapper
        backgroundColor={user && !missingAttributes ? 'white' : 'background'}
      >
        <PageInner>
          {user && !missingAttributes ? (
            <>
              <MyAccountNav navItems={newNavItems} />

              <PageContent>{props.children}</PageContent>
            </>
          ) : (
            <PageContent>{!userLoading && <Authenticate />}</PageContent>
          )}
        </PageInner>
      </PageWrapper>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        canonical={urljoin(getConfig().publicRuntimeConfig.baseUrl, `/minar`)}
        openGraph={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
    </WebPage>
  )
}
