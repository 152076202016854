import {GetServerSideProps} from 'next'
import dynamic from 'next/dynamic'
import {WebPageCommonProps} from 'src/components/layout/WebPage'
import MyAccountPage from 'src/components/myaccount/layout/MyAccountPage'
import {makePageCommonProps} from 'src/utils'

import {onDutyFree} from '@festi/common/constants/channels'
import settings from '@festi/common/constants/settings'

const ReceiptPage = dynamic(
  () => import('src/components/myaccount/ReceiptPage'),
  {ssr: false},
)

export const getServerSideProps: GetServerSideProps = async ({
  res,
  preview = false,
}) => {
  if (onDutyFree) {
    return {
      redirect: {
        destination: `${settings.baseUrl}/minar`,
        permanent: true,
      },
    }
  }
  const common = await makePageCommonProps(preview)
  if (common.categories.length === 0) {
    res.setHeader('Cache-Control', 's-maxage=1, stale-while-revalidate')
  } else {
    res.setHeader('Cache-Control', 's-maxage=60, stale-while-revalidate=3600')
  }
  return {
    props: {
      common,
      preview,
    },
  }
}

interface Props {
  common: WebPageCommonProps
}

export default function Kvittanir({common}: Props) {
  return (
    <MyAccountPage headTitle="ELKO - Mínar síður" common={common}>
      <ReceiptPage />
    </MyAccountPage>
  )
}
